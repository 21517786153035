body {
  font-family: monospace;
  background: black;
  background-color: #000000;
  /* height:100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

.pages {
  color: lime;
}

.pages:hover {
  color: limegreen;
  text-decoration: none;
}

.App {
  font-family: monospace;
  color: lime;
}

#badge {
  padding: 20px;
}

.text { 
  position: relative;
  display: inline-block;
  font-family: monospace;
  font-size: 1.5em;
  color: lime;

}

.text::after {
  content: "_";
  position: absolute;
  /* top: 0; */
  /* right: -.75em; */
  /* Remove display: inline-block if not required to be on the same line as text etc */
  /* display: inline-block; */
  /* background-color: #ffffff; */
  /* vertical-align: top; */
  width: 15px;
  /* Set height to the line height of .text */
  /* height: 32px; */
  /* 
  Animation paramaters:
  blink = animation-name, 
  1s = animation-duration, 
  step-end = animation-timing-function,
  infinite = animation-iteration-count
  */
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}



